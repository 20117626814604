import React from "react";
import { navigate, FormattedMessage } from "gatsby-plugin-intl";

import * as constants from "../app.constant";
import AppLayout from "../components/shared/AppLayout";
import maintenancePageIcon from "../assets/images/maintenance_page_icon.svg";

const PageMaintenance = () => {

    const navigateBack = () => {
        navigate(constants.ROUTES.PLANS);
    };

    return (
        <AppLayout hasHeader={true} hasHeaderLogo={true} hasFooter={true}>
            <div className="app-page page-error">

                <div className="maintenance">
                    <div className="container">
                        <div className="maintenance__header">
                            <h2 className="maintenance__status">
                                <img className="maintenance__status-icon img-fluid" src={maintenancePageIcon} alt="" width="44" />
                                <span className="maintenance__status-text text-bold">
                                    <FormattedMessage id="system.maintenance.title" />
                                </span>
                            </h2>
                            <p>
                                <FormattedMessage id="system.maintenance.description" />
                            </p>
                        </div>

                        <p className="btn-container btn-container--center">
                            <button className="btn btn-lg btn-primary" type="button" onClick={navigateBack}>
                                <FormattedMessage id="system.maintenance.cta" />
                            </button>
                        </p>
                    </div>
                </div>

            </div>
        </AppLayout>

    );
};

export default PageMaintenance;
